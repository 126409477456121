<template>
  <Form class="mt-4 flex w-full flex-col gap-y-2">
    <fieldset
      v-for="field of props.cookiesList"
      :key="field.name"
    >
      <UiFormFieldCheckbox
        :id="field.name"
        :model-value="field.checked || props.cookiesState[field.name]"
        :name="field.name"
        type="checkbox"
        variant="primary"
        class="mr-2.5"
        :disabled="field.disabled"
      >
        <div>
          <p class="font-bold text-primary">
            {{ field.header }}
          </p>
          <p class="text-sm">
            {{ field.text }}
          </p>
        </div>
      </UiFormFieldCheckbox>
    </fieldset>

    <div class="mt-4 flex flex-col justify-between gap-y-2 lg:flex-row">
      <UiButton
        variant="secondary"
        type="submit"
      >
        {{ props.labels.btnSave }}
      </UiButton>
      <slot name="btnAcceptedAll" />
    </div>
  </Form>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import UiButton from '../../UiButton/UiButton.vue'
import UiFormFieldCheckbox from '../../UiForm/UiFormFieldCheckbox/UiFormFieldCheckbox.vue'
import type { UiCookiesManagerForm } from '../UiCookiesManager.types'

const props = defineProps<UiCookiesManagerForm>()

const Form = defineAsyncComponent(async () => {
  const { Form: FormComponent } = await import('vee-validate')
  return FormComponent
})
</script>
